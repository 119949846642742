<template>
  <div class="documents-item">
    <a :href="fileURL" :target="target" class="documents-item__wrapper">
      <div class="documents-item__image">
        <IconPDF :color="iconColor" />
      </div>
      <div :class="classes">
        <div class="document-content__title">
          <span ref="name" class="document-content__name">{{ name }}</span>
          <span ref="type" class="document-content__type">{{ type }}</span>
        </div>
        <div class="document-content__date">
          {{ fileDate }}
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import IconPDF from "@/components/icons/IconPDF";

export default {
  name: "DocumentsItem",
  components: {
    IconPDF,
  },
  props: {
    iconColor: {
      type: String,
    },
    fileName: {
      type: String,
      required: true,
    },
    fileDate: {
      type: String,
      required: true,
    },
    fileURL: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      default: "_self",
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        "documents-item__content document-content": true,
        "documents-item__content_light--text": this.light,
      };
    },
    name() {
      return this.parseFileName().name;
    },
    type() {
      return this.parseFileName().type;
    },
  },
  methods: {
    parseFileName() {
      const fileTypes = [".doc", ".pdf"];
      const title = this.fileName;
      const result = {
        name: title,
        type: "",
      };
      fileTypes.forEach((item, i) => {
        const pos = title.indexOf(item);
        if (pos >= 0) {
          result.name = title.substr(0, pos + 1);
          result.type = title.substr(pos + 1);
        }
      });
      return result;
    },
  },
};
</script>

<style lang="scss">
.documents-item {
  -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
  page-break-inside: avoid; /* Firefox */
  break-inside: avoid; /* IE 10+ */
  box-sizing: border-box;
  & > * {
    box-sizing: border-box;
  }
  &:hover {
    opacity: 0.5;
    transition: all 300ms ease-out;
  }
  &__wrapper {
    display: flex;
    text-decoration: none;
    &:hover {
      .content {
        img {
          text-decoration: none;
        }
        &__date {
          text-decoration: none;
        }
      }
    }
  }
  &__image {
    padding-right: 9px;
    padding-top: 6px;
    display: inline-block;
  }
  &__content_light--text {
    .document-content {
      &__name {
        color: #fff;
      }
      &__date {
        color: #79797a !important;
      }
      &__type {
        color: #fff;
      }
    }
  }
  .document-content {
    width: calc(100% - 29px);
    &__title {
      font-family: $font-family-rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 170%;
      color: #18181b;
      display: flex;
      min-width: 0;

      @media (max-width: $screen-md) {
        font-size: 12px;
      }
    }
    &__name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    &__type {
      flex-shrink: 0;
    }
    &__date {
      font-family: $font-family-bebas;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 135%;
      letter-spacing: 0.05em;
      color: #18181b;
      opacity: 0.5;
    }
  }
}
</style>
