var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "documents-item" }, [
    _c(
      "a",
      {
        staticClass: "documents-item__wrapper",
        attrs: { href: _vm.fileURL, target: _vm.target },
      },
      [
        _c(
          "div",
          { staticClass: "documents-item__image" },
          [_c("IconPDF", { attrs: { color: _vm.iconColor } })],
          1
        ),
        _c("div", { class: _vm.classes }, [
          _c("div", { staticClass: "document-content__title" }, [
            _c("span", { ref: "name", staticClass: "document-content__name" }, [
              _vm._v(_vm._s(_vm.name)),
            ]),
            _c("span", { ref: "type", staticClass: "document-content__type" }, [
              _vm._v(_vm._s(_vm.type)),
            ]),
          ]),
          _c("div", { staticClass: "document-content__date" }, [
            _vm._v(" " + _vm._s(_vm.fileDate) + " "),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }